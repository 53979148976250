@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*
    SALES REPRESENTATIVE STYLE
*/
/*========global=======*/
/* screen sizes */
.rounded-5 {
  border-radius: 10px;
}

h1 {
  letter-spacing: 1px;
}

h4 {
  font-weight: 500 !important;
  font-size: 1.5rem;
}

.primary-colour {
  color: #ffc937;
}

.MuiButton-containedPrimary {
  background: #ffd747;
  background: linear-gradient(0deg, #ffc937 0%, #ffd747 100%);
  color: #2B5A83 !important;
}

.MuiButton-outlinedPrimary {
  border: 1px solid #fff !important;
  color: #fff !important;
  font-size: 1.1rem !important;
}

.whiteColour {
  background: #ffffff !important;
}

.lightColour {
  background: #e0e5eb !important;
}

.blueColour {
  background: #2B5A83 !important;
  color: #fff !important;
}

.blueColorText {
  color: #2B5A83;
}

.NavyColourText {
  color: #2B5A83 !important;
}

.NavyColour {
  background: #2B5A83 !important;
}

.lightGrey {
  background: #f7f8fa !important;
}

.primaryColor {
  background: #ffc937 !important;
}

.white {
  color: #ffffff;
}

.large-copy-size {
  font-size: 18px;
  line-height: 28px;
}

.xlarge-copy-size {
  font-size: 20px;
  line-height: 30px;
}

h5 {
  line-height: 28px !important;
  font-size: 1.4rem !important;
}

h6 {
  line-height: 22px !important;
  font-size: 1.1rem !important;
}

.overlayBox .MuiCard-root {
  border: 2px solid transparent;
  transition: ease .4s;
}

.overlayBox:hover .MuiCard-root {
  border: 2px solid #2B5A83;
  text-decoration: none;
}

.clientSection .alice-carousel__dots {
  display: none;
}

.semiBold {
  font-weight: 600 !important;
}

.mediumBold {
  font-weight: 500;
}

p {
  font-size: 1rem;
  line-height: 1.5;
}

ul li {
  padding: 5px 0;
}

/*===end=====global=======*/
body {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: #000000;
  font-weight: 500;
}

.App {
  text-align: left;
}

.headerBar {
  padding: 0 !important;
}

.darkBg {
  background-color: #333333;
}

.darkBg nav.navbar {
  display: none;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  transition: all 0.3s;
}

.navbar .navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.navbar .nav-item .nav-link {
  color: #000000;
  font-weight: 500;
  padding: 0 20px;
  font-size: 18px;
}

.navbar .nav-item .nav-link.active {
  color: #2B5A83;
}

.navbar .nav-item .nav-link:hover {
  color: #ffc937;
}

/*============= nav-bar ===============*/
.topBar {
  background-color: #2B5A83;
  padding: 7px 0;
  text-align: right;
  color: #ffffff;
}

.topBar .phoneno ul {
  margin: 0;
  padding: 0;
}

.topBar .phoneno ul li {
  display: inline-block;
  padding: 0 10px;
  border-right: 1px solid #cdcdcd;
  font-size: 13px;
  font-weight: 400;
}

.topBar .phoneno ul li span {
  color: #ffc937;
  font-weight: 500;
}

.topBar .phoneno ul li a {
  color: #ffffff;
  font-weight: 500;
}

.headerMenu a {
  text-decoration: none;
}

nav ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

nav ul li a {
  padding: 0 10px;
  position: relative;
  color: #000000;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
}

nav ul li a:hover {
  color: #2B5A83;
}

@media (max-width: 767px) {
  nav ul li a {
    padding: 0 8px;
  }
  .logo img {
    width: 200px;
  }
}

nav ul li a.active {
  color: #2B5A83;
}

@media (max-width: 767px) {
  nav li.navUsername {
    display: none;
  }
}

/*========end===== nav-bar ===============*/
.bgNone {
  background: none !important;
}

.footerBar {
  background: #ddd;
  text-align: center;
}

.topBanner {
  background: #2B5A83;
  color: #ffffff;
}

.topBanner ul li {
  padding: 5px 0;
}

body .developmentPoint {
  background-color: #ffc937;
  color: #ffffff;
}

.w3-spin {
  -webkit-animation: w3-spin 20s infinite linear;
  animation: w3-spin 20s infinite linear;
}

.circle-spin {
  position: relative;
  max-width: 465px;
  margin: auto;
}

.circle-spin img.w3-spin {
  width: 100%;
}

@-webkit-keyframes w3-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes w3-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.spiner-logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 130px;
}

.topBanner textarea {
  border: 1px solid #bbb;
  border-radius: 5px;
  font-size: inherit;
  padding: 10px 13px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  width: 100% !important;
  box-sizing: border-box !important;
}

.expertiseIcon {
  margin-right: 5px;
  min-width: 60px;
}

.expertiseText span {
  font-weight: 500;
}

.expertiseText a {
  text-decoration: none;
}

.expertiseText a:hover {
  color: #000;
}

.expertiseBlock ul {
  padding: 0 !important;
}

.expertiseBlock {
  padding-top: 0 !important;
}

.casestudyDes li {
  padding: 5px 0;
  color: #fff;
  list-style: disc;
}

.counter-box {
  display: block;
  padding: 0;
  text-align: center;
}

.counter-box .counter {
  display: block;
  font-size: 30px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 10px;
}

.counter-box p {
  margin: 7px 0 0;
  padding: 0;
  color: var(--white);
  font-weight: 500;
}

.objectContent {
  object-fit: contain;
}

.alice-carousel__prev-btn {
  display: none !important;
}

.alice-carousel__next-btn {
  display: none !important;
}

.middlePart {
  min-height: 500px;
}

.skewShape {
  -webkit-clip-path: polygon(0 10%, 100% 5%, 100% 91%, 0% 100%);
  clip-path: polygon(0 10%, 100% 5%, 100% 91%, 0% 100%);
}

.pythonDevelopment {
  background: #f7f7f7 !important;
}

.pythonLogo {
  width: 80px;
  margin: -60px auto 20px;
  height: 80px;
  background: #fff;
  border-radius: 50px;
  overflow: hidden;
  border: 4px solid #62809b;
  padding: 5px;
}

.pythonLogo img {
  width: 80px;
  height: 80px;
}

.MuiInputBase-input, textarea {
  font-weight: 500 !important;
}

.serviceIcon {
  width: 90px;
  border-radius: 100px;
  margin: auto;
  background: #fff7e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*======== animation bar ========*/
@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

@keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

.bar {
  height: 5px;
  width: 90px;
  background: #b1c6d9;
  margin: 20px auto;
  position: relative;
  border-radius: 30px;
}

.bar::before {
  content: '';
  position: absolute;
  left: 0;
  top: -2.7px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #2B5A83;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 1200px) {
  .skewShape {
    -webkit-clip-path: polygon(0 2%, 100% 5%, 100% 100%, 0% 100%);
    clip-path: polygon(0 2%, 100% 5%, 100% 100%, 0% 100%);
  }
}

@media (max-width: 991px) {
  h1 {
    line-height: 40px !important;
    font-size: 2rem !important;
  }
}

@media (max-width: 767px) {
  .circle-spin {
    max-width: 250px;
  }
}

/*========== menu section ==========*/
.App .dnarn {
  background: none;
  width: auto;
  position: relative;
}

.App .dnarn nav {
  height: auto;
  width: auto;
}

.App .dnarn nav .nav-items {
  mask-image: inherit;
  -webkit-mask-image: inherit;
}

.App .dnarn nav .nav-items ul li {
  padding: 0;
}

.App .dnarn nav .nav-items ul li a {
  color: #222;
  font-weight: 600;
  font-size: 15px;
}

.App .dnarn nav .nav-items ul li a:hover {
  color: #2B5A83;
}

.App .dnarn nav .nav-items ul li a.active {
  color: #2B5A83;
}

@media (max-width: 779px) {
  .topBar {
    padding: 7px 25px;
  }
  .dnarn nav .nav-items {
    background: #2B5A83 !important;
    top: 0 !important;
    height: 100% !important;
    padding: 0 !important;
  }
  .dnarn nav .nav-items ul {
    margin-top: 60px;
    display: block !important;
  }
  .dnarn nav .nav-items ul li {
    margin: 0 !important;
  }
  .dnarn nav .nav-items ul li a {
    padding: 5px 20px;
    color: #fff !important;
    display: block;
  }
  .dnarn nav .nav-items ul li a:hover {
    color: #ffc937 !important;
  }
  .dnarn .nav-items {
    display: none;
  }
  .dnarn .menu-icon {
    position: fixed;
    margin: 0 5px !important;
    top: 16px !important;
    left: 0;
  }
  .dnarn .menu-icon i {
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    width: 22px;
    height: 13px;
    display: block;
    left: 10px;
    position: relative;
  }
  .dnarn .menu-icon i:before {
    border-top: 3px solid #fff;
    content: "";
    position: absolute;
    top: 0;
    width: 22px;
    height: 2px;
    top: 5px;
    left: 0;
  }
  .dnarn .cancel-icon {
    position: fixed;
    top: 16px;
    right: 0;
    margin: 0 5px !important;
    z-index: 1111;
  }
  .dnarn .cancel-icon i {
    border-top: 3px solid #fff;
    width: 22px;
    height: 13px;
    display: block;
    left: 5px;
    position: relative;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 5px;
  }
  .dnarn .cancel-icon i:before {
    border-top: 3px solid #fff;
    content: "";
    position: absolute;
    top: 0;
    width: 22px;
    top: -3px;
    left: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}

.dnarn nav .logo span {
  display: none;
}

.dnarn nav .logo {
  display: none;
}

.dnarn nav .logo .search-icon {
  display: none;
}

/*========end== menu section ==========*/
.rb-tabs-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  text-transform: uppercase;
}

body .rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item.active {
  font-weight: 700;
}

a {
  cursor: pointer;
}

.blog-post-heading {
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  min-height: 45px;
}

.react-card-front {
  cursor: pointer;
}

.react-card-flipper:hover .react-card-back {
  -webkit-transform: rotateY(0deg) !important;
          transform: rotateY(0deg) !important;
}

.react-card-flipper:hover .react-card-front {
  -webkit-transform: rotateY(-180deg) !important;
          transform: rotateY(-180deg) !important;
}

.popup-overlay {
  z-index: 9999 !important;
}

.popup-overlay .popup-content {
  max-width: 600px;
  width: 100%;
  background: none;
  border: none;
  padding: 15px;
}

.popup-overlay .popup-content form textarea {
  font-family: "Montserrat",Open Sans,sans-serif;
  font-weight: 400 !important;
  font-size: 1rem;
  line-height: 1.4375em;
  padding: 12px;
}

.popupClose {
  position: absolute;
  right: 25px;
  top: 65px;
}

.popupClose button {
  min-width: inherit;
}

.office-address ul {
  padding: 0 0 0 13px;
}

.office-address li {
  font-size: 13px;
}

.office-address a {
  color: #ffc937;
}

.follow-us-icon {
  padding: 0;
  display: flex;
}

.follow-us-icon li {
  list-style: none;
}

.follow-us-icon li a {
  color: #ddd;
  padding-right: 7px;
}

.follow-us-icon li a:hover {
  color: #ffc937;
}

.copyright a {
  color: #ddd;
}

.copyright a:hover {
  color: #ffc937;
}

.quick-link a:hover {
  color: #ffc937;
}

.alice-carousel__dots-item {
  padding: 0;
  width: 12px !important;
  height: 12px !important;
}

.awardIcons {
  background: #fff;
  border-radius: 10px;
  padding: 5px;
}
/*# sourceMappingURL=global.css.map */
